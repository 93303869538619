/* =========================================================================== */

/* @TODO: intended for C# re-overrides */

/* For the following, must be updated from consumer's end (e.g. C#) */

/* KNOWN USAGE: /basket - Save Gift Message, /partner-enquiry - Submit Form */
.siteCanvas .btn--main {
  background: #e10a0a;
}
.siteCanvas .btn--main:focus,
.siteCanvas .btn--main:hover {
  background: #b00808;
}

/* SPECIFIC USAGE: /basket - FAQ note */
.siteCanvas .basketFooter__faqs a {
  color: #e10a0a;
}
.siteCanvas .basketFooter__faqs a:focus,
.siteCanvas .basketFooter__faqs a:hover {
  color: #b00808;
}

/* KNOWN USAGE: /excluded-products, /cookies-are-our-friends */
.siteCanvas .sitePage p {
  margin: 1em 0;
}

/* KNOWN USAGE: /cookies-are-our-friends */
.siteCanvas .sitePage td,
.siteCanvas .sitePage th {
  border: solid 1px gray;
  padding: 2px;
}

/* KNOWN USAGE: /all-locations, /excluded-products, /cookies-are-our-friends */
.siteCanvas .sitePage ul {
  list-style: disc;
  margin-bottom: 1em;
  padding-left: 40px;
}
.siteCanvas .sitePage ul ul {
  list-style: circle;
}

.siteCanvas .sitePage a {
  color: #e10a0a;
}
.siteCanvas .sitePage a:focus,
.siteCanvas .sitePage a:hover {
  color: #b00808;
}

/* SPECIFIC USAGE: /all-locations, /excluded-products, /cookies-are-our-friends, /sitemap - black links instead of red */
.siteCanvas .sitePage .breadcrumbs a,
.siteCanvas .sitePage .breadcrumbs a:focus,
.siteCanvas .sitePage .breadcrumbs a:hover {
  color: #292d2f;
}

/* SPECIFIC USAGE: /all-locations - black links instead of red */
.siteCanvas .sitePage .all-locations a,
.siteCanvas .sitePage .all-locations a:focus,
.siteCanvas .sitePage .all-locations a:hover {
  color: #222;
}

/* SPECIFIC USAGE: /sitemap, /sitemap/h - black links instead of red, no ul bullets and padding */
.siteCanvas .sitePage .alphabet,
.siteCanvas .sitePage .categoryList,
.siteCanvas .sitePage .productList {
  padding: 0;
  list-style: none;
}
.siteCanvas .sitePage .categoryList__first a,
.siteCanvas .sitePage .categoryList__second a,
.siteCanvas .sitePage .productList__item a {
  color: #292d2f;
}
.siteCanvas .sitePage .categoryList__first a:hover,
.siteCanvas .sitePage .categoryList__first a:focus,
.siteCanvas .sitePage .categoryList__second a:hover,
.siteCanvas .sitePage .categoryList__second a:focus,
.siteCanvas .sitePage .productList__item a:hover,
.siteCanvas .sitePage .productList__item a:focus {
  text-decoration: underline;
  color: #e10a0a;
}
.siteCanvas .sitePage .alphabet__item a {
  color: #292d2f;
}
.siteCanvas .sitePage .alphabet__item a.active,
.siteCanvas .sitePage .alphabet__item a:hover,
.siteCanvas .sitePage .alphabet__item a:focus {
  color: #ffffff;
}

/* SPECIFIC USAGE: /contact - button should be text white */
.siteCanvas .sitePage a.ctype {
  background: #e10a0a;
  color: #ffffff;
}
.siteCanvas .sitePage a.ctype:hover,
.siteCanvas .sitePage a.ctype:focus {
  background: #d20000;
  color: #ffffff;
}

/* KNOWN USAGE: /all-locations, /excluded-products, /cookies-are-our-friends, /reviews - main content container wrapper */
.siteCanvas .container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) {
  .siteCanvas .container {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1440px) {
  .siteCanvas .container {
    max-width: 1440px;
  }
}

/* For the following, remove once Footer 2024 is implemented */

footer:not(#footer-2024) [data-testid='carousel-track'] {
  margin-bottom: 1em;
}

footer:not(#footer-2024) [data-testid='carousel-track'] > li > a div > h3 {
  margin: 1em 0;
}

/* =========================================================================== */

@font-face {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('../public/assets/fonts/proximaNova/3158E9_4_0.woff2') format('woff2');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('../public/assets/fonts/proximaNova/3158E9_5_0.woff2') format('woff2');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../public/assets/fonts/proximaNova/3158E9_1_0.woff2') format('woff2');
}

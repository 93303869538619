@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-inter: 'Inter';
    --font-serif: 'DM Serif Text';
    --font-serif-display: 'DM Serif Display';
    /* text */
    --text-neutral: #2b3240;
    --text-neutral-strong: #1f242e;
    --text-neutral-faded: #686f7d;
    --text-neutral-faded-high-contrast: #686f7d;
    --text-link: #e10a0a;
    --text-brand: #e10a0a;
    --text-brand-hover-dark: #a80c0c;

    /* border */
    --border-neutral: #d1d5db;
    --border-neutral-strong: #979eab;
    --border-neutral-faded: #f3f4f6;
    --border-neutral-inverse: #2b3240;
    --border-neutral-high-contrast: #d1d5db;
    --border-primary: #a80c0c;
    --border-primary-faded: #ffdfdf;
    --border-disabled: #e5e7eb;

    /* background */
    --background-page: #ffffff;
    --background-page-faded: #f9fafb;
    --background-primary: #e10a0a;
    --background-primary-faded: #ffdfdf;
    --background-primary-faded-lighter: #fff1f1;
    --background-primary-faded-hover: #ffc4c4;
    --background-primary-highlight: #f21010;
    --background-neutral: #979eab;
    --background-neutral-strong: #1f242e;
    --background-neutral-faded: #f3f4f6;
    --background-disabled: #e5e7eb;
    --background-elevation-base: #ffffff;
    --background-elevation-raised: #ffffff;
    --background-elevation-overlay: #ffffff;

    /* onbackground */
    --on-background-neutral: #000000;
    --on-background-primary: #ffffff;
    --on-background-warn: #000000;
    --on-background-positive: #000000;

    /** persistent */
    --persistent-white: #ffffff;
    --persistent-text-light: #e5e7eb;
    --persistent-text-dark: #2b3240;
    --persistent-bg-primary: #e10a0a;
    --persistent-text-dark-strong: #1f242e;
    --persistent-text-dark-faded: #686f7d;
    --persistent-bg-primary-faded: #ffdfdf;
    --persistent-bg-primary-faded-hover: #ffc4c4;
    --persistent-border-disabled: #e5e7eb;
    --persistent-border-translucent-light: #b3b6b434;
    --persistent-border-neutral-dark: #2b3240;

    /** nav items */
    --nav-item-text-hover: var(--text-brand);
    --nav-item-bg-hover: var(--background-primary-faded);

    /* others */
    --icon-star-filled: #fed141;
    --icon-star-empty: #d1d5db;
    --neon-shadow: var(--persistent-bg-primary);
    --promo-bf: var(--persistent-bg-primary);
    --promo-cm: #0082f9;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-serif;
    font-weight: bold;
  }

  .dark {
    /* text */
    --text-neutral: #d1d5db;
    --text-neutral-strong: #e5e7eb;
    --text-neutral-faded: #979eab;
    --text-neutral-faded-high-contrast: #d1d5db;
    --text-link: #f21010;
    --text-brand: #f21010;

    /* border */
    --border-neutral: #2b3240;
    --border-neutral-strong: #686f7d;
    --border-neutral-faded: #1f242e;
    --border-neutral-inverse: #d1d5db;
    --border-neutral-high-contrast: #686f7d;
    --border-primary: #a80c0c;
    --border-primary-faded: #a80c0c;
    --border-disabled: #2b3240;

    /* background */
    --background-page: #0f131a;
    --background-page-faded: #14181f;
    --background-primary: #f21010;
    --background-primary-faded: #1f242e;
    --background-primary-faded-lighter: #2b3240;
    --background-primary-faded-hover: #a80c0c;
    --background-primary-highlight: #ff2f2f;
    --background-neutral: #1f242e;
    --background-neutral-strong: #d1d5db;
    --background-neutral-faded: #2b3240;
    --background-disabled: #2b3240;
    --background-elevation-base: #0f131a;
    --background-elevation-raised: #14181f;
    --background-elevation-overlay: #1f242e;

    /* onbackground */
    --on-background-neutral: #ffffff;
    --on-background-primary: #ffffff;
    --on-background-warn: #000000;
    --on-background-positive: #000000;

    /** nav items */
    --nav-item-text-hover: #ffffff;
    --nav-item-bg-hover: var(--background-primary);
  }
}
